<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="mains">
          <div class="left">
            <!-- 步骤 -->
            <div class="steps">
              <!-- 步骤1 -->
              <div class="steps-item" :class="{'blue': oneStep}">
                <div v-if="!oneSuccess" class="steps-item-num">
                  <span>1</span>
                </div>
                <div v-else class="icon">
                  <i class="el-icon-check"></i>
                </div>
                <div class="title" :class="{'suctitle': oneSuccess}">填写工单</div>
                <div v-if="!oneSuccess" class="line"></div>
                <div v-else class="blueline"></div>
              </div>
              <!-- 步骤2 -->
              <div class="steps-item" :class="{'blue': twoStep}">
                <div v-if="!twoSuccess" class="steps-item-num">
                  <span>2</span>
                </div>
                <div v-else class="icon">
                  <i class="el-icon-check"></i>
                </div>
                <div class="title" :class="{'suctitle': twoSuccess}">确认信息</div>
                <div v-if="!twoSuccess" class="line"></div>
                <div v-else class="blueline"></div>
              </div>
              <!-- 步骤3 -->
              <div class="steps-item" :class="{'blue': threeStep}">
                <div class="steps-item-num">
                  <span>3</span>
                </div>
                <div class="title">完成</div>
              </div>
            </div>
            <!-- 表单 -->
            <div class="form">
              <el-form v-show="oneStep" label-width="80px" :model="formData">
                <el-form-item label="问题类型:">
                  <el-radio-group v-model="radio" @change="radioChange">
                    <el-radio :label="0">底单申请</el-radio>
                    <el-radio :label="1">物流异常</el-radio>
                    <el-radio :label="2">其他问题</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="工单标题:">
                  <el-input v-model="formData.title"></el-input>
                </el-form-item>
                <el-form-item :label="textareaTitle">
                  <el-input v-model="formData.text" type="textarea" :rows="5" resize="none" :placeholder="textareaPlaceholder"></el-input>
                </el-form-item>
                <el-form-item class="upImg" label="上传图片:" v-show="showImg" >
                  <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="uploadFile"
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :before-upload="beforePicUpload"
                    :file-list="fileList">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label="联系人:">
                  <el-input v-model="username" disabled></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="nextStep">下一步</el-button>
                </el-form-item>
              </el-form>
              <el-form v-show="twoStep" label-width="80px">
                <el-form-item label="问题类型:">
                  <span v-show="formData.matterType === '1'">底单申请</span>
                  <span v-show="formData.matterType === '2'">物流异常</span>
                  <span v-show="formData.matterType === '3'">其他问题</span>
                </el-form-item>
                <el-form-item label="工单标题:">{{formData.title}}</el-form-item>
                <el-form-item :label="textareaTitle">{{formData.text}}</el-form-item>
                <el-form-item v-show="showImg" label="上传图片:">
                  <div>
                    <el-image
                      class="imgItem"
                      v-for="(item, index) in fileList"
                      :key="index"
                      style="width: 100px; height: 100px"
                      :src="item.url"
                      >
                    </el-image>
                  </div>
                </el-form-item>
                <el-form-item label="联系人:">{{username}}</el-form-item>
                <el-form-item>
                  <el-button @click="lastStep">上一步</el-button>
                  <el-button type="primary" @click="submit">提交</el-button>
                </el-form-item>
              </el-form>
              <div class="threeStep" v-show="threeStep">
                <div class="ant-result">
                  <img src="~assets/images/mall/checkmark.png">
                </div>
                <div class="czcg">操作成功</div>
                <div class="nxdd">请耐心等待结果，详情可见-我的工单</div>
                <div>
                  <el-button type="primary" @click="applyAgain">再次申请</el-button>
                  <el-button @click="toMyOrder">查看详情</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">常见问题工单</div>
            <div class="content">
              <div class="item" v-for="(item, index) in helpData" :key="index">
                <div class="tit" :class="{'activeBlue': showCont === index}" @click="openClick(index)"><span></span>{{item.title}}</div>
                <div v-show="showCont === index" class="cont" v-html="item.content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { matterWrite, imgsHome, matterResolve } from 'network/api'

export default {
  components: {
    AppMain
  },
  data () {
    return {
      showImg: false,
      radio: 0,
      textareaTitle: '物流单号:',
      textareaPlaceholder: '多个单号用逗号隔开',
      formData: {
        matterType: '1', // 工单类型
        title: '底单申请',
        text: ''
      },
      fileList: [],
      oneSuccess: false,
      twoSuccess: false,
      threeSuccess: false,
      oneStep: true,
      twoStep: false,
      threeStep: false,
      helpData: [],
      showCont: null,
      matterimages: '', // 上传图片地址拼接字符串
      uploadImg: []
    }
  },
  computed: {
    username () {
      return this.$store.getters.getUserInfo.username
    }
  },
  created () {
    matterResolve({ appkey: this.$appkey }).then(res => {
      this.helpData = res.data
    })
  },
  methods: {
    radioChange (val) {
      switch (val) {
        case 0:
          this.formData.matterType = '1'
          this.formData.title = '底单申请'
          this.textareaTitle = '物流单号:'
          this.textareaPlaceholder = '多个单号用逗号隔开'
          this.showImg = false
          this.formData.text = ''
          this.fileList = []
          break
        case 1:
          this.formData.matterType = '2'
          this.formData.title = '物流异常'
          this.textareaTitle = '问题描述:'
          this.textareaPlaceholder = '多个单号用逗号隔开'
          this.showImg = false
          this.formData.text = ''
          this.fileList = []
          break
        case 2:
          this.formData.matterType = '3'
          this.formData.title = '其他问题'
          this.textareaTitle = '问题描述:'
          this.textareaPlaceholder = 'bug或优化建议'
          this.showImg = true
          this.formData.text = ''
          this.fileList = []
          break
      }
    },
    // 上传图片回调
    handleChange (file, fileList) {
      // console.log(fileList)
      this.fileList = fileList.slice(-5)
    },
    // 删除上传图片回调
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    // 自定义上传
    uploadFile (file) {
      imgsHome(file.file).then(res => {
        if (res.code === 1) {
          // 将上传成功得到的url 存入一个数组 只有5个对应fileList
          this.uploadImg.push(res.data)
          this.uploadImg = this.uploadImg.slice(-5)
          // 将uploadImg数组下标里的url 放到fileList数组对应下标
          for (let i = 0; i < this.uploadImg.length; i++) {
            this.fileList[i].realUrl = this.uploadImg[i]
          }
        }
      })
    },
    // 下一步
    nextStep () {
      if (this.formData.title === '' || this.formData.text === '') {
        this.$message.error('请输入完整的内容')
        return false
      }
      this.oneSuccess = true
      this.oneStep = false
      this.twoStep = true
    },
    // 提交
    submit () {
      for (let i = 0; i < this.fileList.length; i++) {
        this.matterimages += this.fileList[i].realUrl + ','
      }
      this.matterimages = this.matterimages.substring(0, this.matterimages.length - 1)
      matterWrite({ appkey: this.$appkey, matter_type: this.formData.matterType, title: this.formData.title, content: this.formData.text, matterimages: this.matterimages }).then(res => {
        if (res.code === 1) {
          this.twoSuccess = true
          this.twoStep = false
          this.threeStep = true
        }
      })
    },
    // 上一步
    lastStep () {
      this.oneSuccess = false
      this.oneStep = true
      this.twoStep = false
    },
    // 再次申请
    applyAgain () {
      this.threeStep = false
      this.oneStep = true
      this.oneSuccess = false
      this.twoSuccess = false
      this.showImg = false
      this.formData = {
        matterType: '1', // 工单类型
        title: '底单申请',
        text: ''
      }
      this.radio = 0
      this.fileList = []
      this.uploadImg = []
      this.matterimages = ''
    },
    // 展开问题
    openClick (index) {
      if (this.showCont === index) {
        this.showCont = null
        return false
      }
      this.showCont = index
    },
    // 上传图片之前的钩子
    beforePicUpload (file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg' || testmsg === 'JPG' || testmsg === 'png' || testmsg === 'PNG'
      const isLt50M = file.size / 1024 / 1024 < 2
      if (!extension) {
        this.$message({
          message: '上传文件只能是jpg或者png格式!',
          type: 'error'
        })
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 2MB!',
          type: 'error'
        })
        return false
      }
      return extension || isLt50M
    },
    toMyOrder () {
      this.$router.push('/workOrder/myOrder')
    }
  }
}
</script>

<style scoped src="styles/views/workOrder/orderSumbit.css">
</style>

<style>
/* 上传图片框样式 */
.upImg .el-upload--picture-card{
  width: 100px;
  height: 100px;
  font-size: 16px !important;
}
.upImg .el-upload{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}
.upImg .el-upload-list--picture-card .el-upload-list__item{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.upImg .el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 1;
  font-size: 16px;
}

.upImg .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}
.upImg .avatar{
  width: 100px;
  height: 100px;
}
</style>
